@import "styles/utils";

.containerContent {
  width: 100%;
  height: 100%;
  align-self: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 260px 0;
  @include phone {
    margin: 110px 0 0;
  }
  @include phone-landscape {
    margin: 120px 0 0;
  }
}

.notFound {
  font-size: 50px;
  font-weight: bold;

  @include tablet-portrait {
    max-width: 100%;
    width:auto;
    padding:20px;
  }

  @include phone-landscape {
    font-size: 24px;
    line-height: 27px;
    margin: 0 27px 0 27px ;
  }

  @include phone {
    font-size: 24px;
    line-height: 27px;
    margin: 0 27px 0 27px ;
  }
}

.errorText {
  font-size: 22px;
  font-weight: lighter;
  line-height: 24px;
  max-width: 100%;

  @include tablet-portrait{
    max-width: 100%;
    width:auto;
    padding:50px;
  }

  @include tablet-landscape {
    max-width: 100%;
    width:auto;
    padding:50px 150px 50px 150px;
  }

  @include phone-landscape {
    font-size: 16px;
    line-height: 20px;
    text-wrap: normal;
    text-align: center;
    max-width: 100%;
    padding:20px 40px 20px 40px;
    width: auto;
  }

  @include phone {
    font-size: 16px;
    line-height: 20px;
    text-wrap: normal;
    text-align: center;
    max-width: 100%;
    width: auto;
    margin:50px 0 78px;
    padding-left: 56px;
    padding-right:56px;
  }
}

.buttonWraper {
  margin: 28px 0 26px 0;
  text-align: center;

  button {
    width: 323px;
    height: 70px;
    @include phone-landscape {
      width: 230px;
      height: 50px;
    }
    @include phone() {
      width: 230px;
      height: 50px;
    }
  }

}
